<template>
  <div>
    <!-- Activator Slot dengan Props onClick -->
    <slot name="activator" :props="{ onClick: emitUpdateDialog }"></slot>

    <!-- Transition untuk Animasi Fade -->
    <transition name="fade" mode="out-in">
      <div
        v-if="isOpen"
        class="fixed inset-0 z-[998] flex justify-center items-center overflow-y-auto h-screen px-10"
      >
        <!-- Overlay Latar Belakang -->
        <div class="fixed inset-0 bg-black opacity-50"></div>

        <!-- Kontainer Dialog dengan Safe Zone -->
        <div class="relative mt-10 max-h-screen p-10 safe-zone">
          <!-- Elemen dengan Kelas Animasi -->
          <div :class="transitionClass">
            <slot></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'PadmaErpVDialog',
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    animated: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isOpen: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    transitionClass() {
      return this.isOpen
        ? this.animated
          ? 'bounce-enter-active'
          : ''
        : 'bounce-leave-active';
    },
  },
  watch: {
    modelValue(newValue) {
      this.isOpen = newValue;
    },
  },
  methods: {
    emitUpdateDialog() {
      this.$emit('update:modelValue', !this.modelValue);
    },
  },
};
</script>

<style lang="scss" scoped>
/* Transition Fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Definisi Animasi Bounce */
@keyframes bounce {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(1.2);
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}

@keyframes bounce-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}

/* Aplikasi Animasi pada Enter dan Leave */
.bounce-enter-active {
  animation: bounce 0.7s;
}

.bounce-leave-active {
  animation: bounce-out 0.5s;
}

/* Styling Safe Zone */
.safe-zone {
  padding-top: env(safe-area-inset-top);
  padding-bottom: calc(env(safe-area-inset-bottom) + 20px); /* Tambahkan ekstra padding untuk bottom */
  box-sizing: border-box;
}

/* Tambahan Styling untuk Responsivitas (Opsional) */
@media (max-width: 768px) {
  .safe-zone {
    padding: 20px env(safe-area-inset-left) 20px env(safe-area-inset-right);
  }
}
</style>
