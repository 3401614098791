<template>
    <div class="flex flex-wrap justify-center">
        <div
            class="p-4"
            :class="computedClasses"
            v-bind="$attrs"
            :style="computedStyle"
        >
            <div>
                <slot name="card-title"></slot>
            </div>
            <slot name="card-body"></slot>
            <div class="mt-4">
                <slot name="card-action"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PadmaErpVCard',
    props: {
        variant: {
            type: String,
            default: 'default',
        },
        overflow: {
            type: Boolean,
            default: true,
        },
        addPaddingBottom: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        computedClasses() {
            return [
                this.variant === 'default' ? 'bg-white' : this.variant,
                'shadow',
                'p-4',
                this.overflow ? 'overflow-hidden' : '',
                'w-full',
                'rounded-lg',
            ];
        },
        computedStyle() {
            return this.addPaddingBottom
                ? {
                      paddingBottom: `calc(16px + env(safe-area-inset-bottom))`,
                  }
                : {};
        },
    },
};
</script>

<style lang="scss" scoped>
/* Optional: Styling tambahan jika dibutuhkan */
.flex {
    display: flex;
}

.justify-center {
    justify-content: center;
}

.p-4 {
    padding: 1rem;
}

.shadow {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.bg-white {
    background-color: #fff;
}

.rounded-lg {
    border-radius: 0.5rem;
}

.w-full {
    width: 100%;
}

/* Global support for safe area padding in case of overlays or full-page modals */
.overlay {
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
}
</style>
